import './styles.scss'
import Markdown from 'markdown-to-jsx'
import React, { useState } from 'react'

export const BotMessage = React.memo(({item, animated, scrollToBottom, handleAnimated}) => {

    const [showDax, setShowDax] = useState(false)

    const headers = item.message.value.length > 0 ? Object.keys(item.message.value[0]) : [];
    const rows = item.message.value.length > 0 ? item.message.value.map(item => Object.values(item)) : [];

    return (
        <main className='bot-message-ai'>
            <>
                <p>{item.message.description}</p>
                {
                    item.message.value.length > 0 ?
                        <table>
                        <thead>
                            <tr>
                                {headers.map(header => <th key={header}>{header}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => (
                                <tr key={index}>
                                    {row.map((cell, index) => <td key={index}>{cell}</td>)}
                                </tr>
                            ))}
                        </tbody>
                        </table>
                        :
                        <p>Nenhum valor encontrado!</p>
                }
                {  
                    showDax &&
                    <pre>
                        {item.message.querie}                            
                    </pre>
                }
                <span className='message-tool'>
                    <div className='tool-item' onClick={() => setShowDax(!showDax)}>
                        <i className='mdi mdi-code-tags '/>
                        <p>{showDax ? 'Ocultar DAX' : 'Visualizar DAX'}</p>
                    </div>
                    <div className='tool-item'>
                        <i className='mdi mdi-clock-fast' />
                        <p>{item.message.time && (item.message.time / 1000).toFixed(2)}s</p>
                    </div>
                </span>
            </> 
        </main>
    )
})