import './style.css'
import Item from '../item'
import React, { useEffect, useState } from 'react'
import Connection from '../connection'
import * as Icon from '@icon-park/react'
import { useHistory } from 'react-router-dom'
import { storage } from 'utils'
import SubGroupItem from '../subGroup'

export default function GroupItem({ item, first, last }) {
  const history = useHistory()
  const user = storage.get('@tijuca-usuario')
  const [isOpen, setIsOpen] = useState(false)
  const [icone, setIcone] = useState('AllApplication')
  var numeric = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']

  function pushToDashboard() {
    if (item[0][1] === 'Home') {
      history.push('/home')
    } else if (item[0][1] === 'SalesReport') {
      history.push('/pages/admin/relatorios')
    } else if (item[0][1] === 'PeopleSafe') {
      history.push('/pages/admin/usuarios')
    } else if (item[0][1] === 'Trophy') {
      history.push('/metrics')
    } else if (item[0][1] === 'Robot') {
      history.push('/pages/admin/squadai')
    } else if (item[0][1] === 'FileEditingOne') {
      history.push('/pages/admin/assinaturas')
    } else {
      history.push('/')
    }
    storage.set('@pathAtual', item[0][1])
  }

  // Função feita para analisar os icones da area de negocio, caso tenha algum problema ao encontrar o valor, o icone subgrupo será setado como AllApplication por padrão
  const verificarIcone = () => {
    if (item && Array.isArray(item) && item.length > 0) {
      if (
        item[0].length > 1 &&
        !(item[0][1] === '-' || numeric.includes(item[0][1]))
      ) {
        setIcone(item[0][1])
      } else if (item[1] && Array.isArray(item[1]) && item[1].length > 0) {
        const firstItem = item[1][0]
        if (
          firstItem.icon &&
          (firstItem.icon.includes('mdi mdi') ||
            firstItem.icon.includes('mdi-'))
        ) {
          setIcone('AllApplication')
        } else if (firstItem.icon) {
          setIcone(firstItem.icon)
        } else {
          setIcone('AllApplication')
        }
      } else {
        setIcone('AllApplication')
      }
    } else {
      setIcone('AllApplication')
    }
  }

  useEffect(() => {
    verificarIcone()
  }, [])

  const getURL = window.location.host
  const redeSim = [
    'jucisrs',
    'jucea',
    'jucerr',
    'jucap',
    'juceac',
    'jucisdf',
    'jucems',
    'jucec'
  ]

  const splitFirstDash = str => {
    const firstDashIndex = str.indexOf('-')
    if (firstDashIndex !== -1) {
      return str.slice(firstDashIndex + 1).trim()
    }
    return str
  }

  return (
    <div className="group-item-container">
      <div className="group-item-part" onClick={() => setIsOpen(!isOpen)}>
        {user &&
        (redeSim.includes(storage.get('@codEmpresa')) ||
          getURL.includes('bi-redesim')) ? (
          <></>
        ) : (
          <Connection first={first} last={last} />
        )}
        <div
          className="group-item-info"
          onClick={item.length > 1 ? () => {} : pushToDashboard}
        >
          <div className="group-item-info-icon">
            {React.createElement(Icon[icone], {
              theme: 'outline',
              size: 14,
              fill: '#eee'
            })}
          </div>
          <div className="group-item-info-name">
            <p className="group-name-item">
              {!(item[0][1] === '-' || numeric.includes(item[0][1]))
                ? item[0][0].split('-')[1]
                : splitFirstDash(item[0])}
            </p>
          </div>
        </div>
        <div className="group-item-down">
          {item.length > 1 && <i className="mdi mdi-menu-down group-icon" />}
        </div>
      </div>
      {isOpen && item.length > 1 && (
        <div
          className={
            user &&
            (redeSim.includes(storage.get('@codEmpresa')) ||
              getURL.includes('bi-redesim'))
              ? !last
                ? 'group-item-items-sim'
                : 'group-item-items-last'
              : !last
              ? 'group-item-items'
              : 'group-item-items-last'
          }
        >
          {Array.from(
            new Set(
              item[1]
                .filter(
                  prop =>
                    prop.id_sub_grupo &&
                    Number(prop.id_sub_grupo) &&
                    Number(prop.status_sub_grupo) === 1
                )
                .map(prop => prop.id_sub_grupo)
            )
          ).map((subGroupId, index, array) => {
            const subItems = item[1].filter(
              subItem =>
                subItem.id_sub_grupo === subGroupId &&
                Number(subItem.id_sub_grupo) &&
                Number(subItem.status_sub_grupo) === 1
            )
            return (
              <React.Fragment key={subGroupId}>
                {subItems.length > 0 && (
                  <SubGroupItem
                    item={subItems}
                    group={
                      !(item[0][1] === '-' || numeric.includes(item[0][1]))
                        ? item[0][0]
                        : item[0]
                    }
                    last={
                      index === array.length - 1 &&
                      item[1].filter(
                        prop =>
                          !(
                            prop.id_sub_grupo &&
                            Number(prop.id_sub_grupo) &&
                            Number(prop.status_sub_grupo) === 1
                          )
                      ).length === 0
                    }
                  />
                )}
              </React.Fragment>
            )
          })}
          {item[1]
            .filter(
              prop =>
                !(
                  prop.id_sub_grupo &&
                  Number(prop.id_sub_grupo) &&
                  Number(prop.status_sub_grupo) === 1
                )
            )
            .map((prop, key, array) => (
              <Item
                item={prop}
                group={
                  !(item[0][1] === '-' || numeric.includes(item[0][1]))
                    ? item[0][0]
                    : item[0]
                }
                key={key}
                last={key === array.length - 1}
              />
            ))}
        </div>
      )}
    </div>
  )
}
